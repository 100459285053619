import React from 'react';
import { useExecutive } from '../api/getExecutive';
import { useParams } from 'react-router-dom';

const OverviewHeader = (): JSX.Element => {
    const { executiveId } = useParams<string>();
    if (!executiveId) return <></>;
    const { executive } = useExecutive(executiveId);

    if (!executive) {
        return <></>;
    }
    return (
        <>
            <h1>{executive.name}</h1>
            <p>{executive.summary}</p>
        </>
    );
};

export default OverviewHeader;

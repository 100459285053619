import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import ApiClient from '~/utils/apiClient';

const BillingPortalLink = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const openBillingPortal = async () => {
        setIsLoading(true);
        const response = await ApiClient.get('/api/subscription/portal');
        window.open(response.url, '_blank', 'noopener noreferrer');
        setIsLoading(false);
    };

    return (
        <div className="flex flex-row justify-between items-center mb-3 xl:mb-5">
            <div
                className="text-base font-primary font-semibold text-black hover:text-verdant cursor-pointer"
                onClick={e => openBillingPortal()}
            >
                <i className="fal fa-external-link fa-fw ml-2"></i>
                Manage Billing
            </div>
            {isLoading && (
                <span style={{ maxWidth: 20 }}>
                    <Loader type="TailSpin" color="#159A1F" height={20} width={20} />
                </span>
            )}
        </div>
    );
};

export default BillingPortalLink;

import React from 'react';

const Documents = (): JSX.Element => {
    return (
        <section className="mt-10 pt-5 border-t border-gray-200">
            <div className="flex flex-row justify-between align-text-bottom">
                <h1 className="font-normal mb-5">Document Mentions</h1>
                <a href="/app/executives/1/themes" className="text-verdant hover:text-verdant underline">
                    Explore Documents
                </a>
            </div>
            <div className="grid grid-cols-3 gap-x-10">
                <div className="flex w-full h-64 bg-gray-200 justify-center items-center">
                    <p>Coming Soon</p>
                </div>
                <div className="flex w-full h-64 bg-gray-200 justify-center items-center">
                    <p>Coming Soon</p>
                </div>
                <div className="flex w-full h-64 bg-gray-200 justify-center items-center">
                    <p>Coming Soon</p>
                </div>
            </div>
        </section>
    );
};

export default Documents;

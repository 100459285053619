import React from 'react';
import { cn } from '~/utils/ui';

const TrendItem = ({ theme, change }: { theme: string; change: number }): JSX.Element => {
    return (
        <div className="flex flex-col">
            <span className="text-[16px] font-bold">{theme}</span>
            <span className={cn('text-[40px] font-bold', change > 0 ? 'text-green-500' : 'text-red-500')}>
                {change}%
            </span>
            <span>Past 30 days</span>
        </div>
    );
};

const ThemeTrends = (): JSX.Element => {
    return (
        <section className="mt-10 pt-5 border-t border-gray-200">
            <div className="flex flex-row justify-between align-text-bottom">
                <h1 className="font-normal mb-5">Trends</h1>
                <a href="/app/executives/1/themes" className="text-verdant hover:text-verdant underline">
                    View All
                </a>
            </div>
            <div className="flex flex-row justify-between space-x-10">
                <TrendItem theme="Community Impact: Health" change={40} />
                <TrendItem theme="Emissions" change={-30} />
                <TrendItem theme="Diversity & Equality" change={18} />
                <TrendItem theme="Business Ethics" change={22} />
                <TrendItem theme="Research" change={-148} />
                <TrendItem theme="Shareholder Rights" change={51} />
            </div>
        </section>
    );
};

export default ThemeTrends;

import React from 'react';

import { useTopSectorThemes } from '../api/getTopSectorThemes';
import { useTopCompanyThemes } from '../api/getTopCompanyThemes';
import { useTopThemes } from '../api/getTopThemes';
import { useParams } from 'react-router-dom';
import SkeletonCard from '~/components/skeletonCard';
import { useExecutive } from '../api/getExecutive';

const ThemeComparison = (): JSX.Element => {
    const { executiveId } = useParams<string>();
    if (!executiveId) return <></>;
    const { executive, isLoading } = useExecutive(executiveId);

    const { themes: topSectorThemes } = useTopSectorThemes(executiveId);
    const { themes: topCompanyThemes } = useTopCompanyThemes(executiveId);
    const { themes: topThemes } = useTopThemes(executiveId);

    if (!executive) {
        return <></>;
    }
    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!topThemes) {
        return <></>;
    }
    return (
        <section className="mt-10 pt-5 border-t border-gray-200">
            <div className="flex flex-row justify-between align-text-bottom">
                <h1 className="font-normal mb-5">Top Themes</h1>
            </div>
            <div className="flex flex-row justify-between">
                <div>
                    <h2 className="font-bold">{executive.name}</h2>
                    {topThemes
                        ? topThemes.map((theme: any) => (
                              <div key={theme.themeName} className="flex flex-row mt-3 align-top">
                                  <h3>{theme.themeName}</h3>
                              </div>
                          ))
                        : topThemes}
                </div>
                <div>
                    <h2 className="font-bold">{executive?.company.name}</h2>
                    {topCompanyThemes
                        ? topCompanyThemes.map((theme: any) => (
                              <div key={theme.themeName} className="flex flex-row mt-3 align-top">
                                  <h3>{theme.themeName}</h3>
                              </div>
                          ))
                        : null}
                </div>
                <div>
                    <h2 className="font-bold">{executive?.company.sector.name}</h2>
                    {topSectorThemes
                        ? topSectorThemes.map((theme: any) => (
                              <div key={theme.themeName} className="flex flex-row mt-3 align-top">
                                  <h3>{theme.themeName}</h3>
                              </div>
                          ))
                        : null}
                </div>
            </div>
        </section>
    );
};

export default ThemeComparison;

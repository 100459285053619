import React from 'react';
import { useHits, UseHitsProps } from 'react-instantsearch';
import ResultCard from '../content/resultCard';
import Loading from '~/components/loading';

const CardRow = (props: UseHitsProps): JSX.Element => {
    const { hits, results } = useHits(props);
    if (!hits || typeof results?.nbHits != 'undefined') {
        return (
            <div>
                {hits.map((hit: any) => {
                    return <ResultCard hit={hit} key={hit.objectID || hit.uuid} />;
                })}
            </div>
        );
    } else {
        return <Loading />;
    }
};

export default CardRow;

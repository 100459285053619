import React from 'react';
import { ContainerWrapper } from '../../layout/container';
import ThemeTrendChart from '../components/themeTrendChart';
import TopVoicesByTheme from '../components/topVoicesByTheme';
import ThemeBySectorChart from '../components/themeBySectorChart';
import RecentContentByTheme from '../components/recentContentByTheme';
import ExecutiveBioAndThumbnail from '../components/executiveBioAndThumbnail';
import ExecutiveThemeComparison from '../components/executiveThemeComparison';
import RelatedExecutives from '../components/relatedExecutives';

const Overview = (): JSX.Element => {
    return (
        <ContainerWrapper className="px-3 background-offset xl:px-0">
            <div className="flex flex-col mx-5 my-5 xl:flex-row xl:space-x-5 xl:container xl:mx-auto 2xl:px-5 2xl:mt-10 2xl:space-x-10">
                <div className="w-full">
                    <h1 className="mb-5">Featured Executive</h1>
                    <ExecutiveBioAndThumbnail />
                    <ExecutiveThemeComparison />
                    <RelatedExecutives />

                    <h1 className="mt-10 mb-5">Featured Theme - AI Ethics</h1>
                    <ThemeTrendChart />
                    <div className="mt-5">
                        <ThemeBySectorChart />
                    </div>
                    <TopVoicesByTheme />
                    <RecentContentByTheme />
                </div>
            </div>
        </ContainerWrapper>
    );
};

export default Overview;

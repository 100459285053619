import React from 'react';
import { Menu } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '~/hooks/useAuth';
import BillingPortalLink from './billingLink';

const AccountDropDown = (): JSX.Element => {
    const { isAdmin } = useAuth();

    return (
        <Menu as="div">
            <div>
                <Menu.Button className="pl-5 border-l-2 border-gray-200 hover:text-green-600">
                    <i className="mr-1 text-xl fal fa-chevron-down fa-fw xl:text-base text-verdant"></i>
                    <span className="font-bold text-black font-size-1em font-primary">Account</span>
                </Menu.Button>
            </div>
            <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1">
                    <Menu.Item as="div">
                        <NavLink
                            end
                            to={`/app/saved`}
                            className={({ isActive }) =>
                                'text-black hover:text-verdant font-primary group flex rounded-md items-center w-full my-3 py-1 px-3 text-base' +
                                (isActive ? ' verdant' : '')
                            }
                        >
                            <i className="mr-2 fal fa-heart fa-fw text-verdant"></i>
                            Saved
                        </NavLink>
                        <NavLink
                            end
                            to={`/app/alerts`}
                            className={({ isActive }) =>
                                'text-black hover:text-verdant font-primary group flex rounded-md items-center w-full my-3 py-1 px-3 text-base' +
                                (isActive ? ' verdant' : '')
                            }
                        >
                            <i className="mr-2 fal fa-bell fa-fw text-verdant"></i>
                            Alerts
                        </NavLink>
                        <NavLink
                            end
                            to={`/app/settings/profile`}
                            className={({ isActive }) =>
                                'text-black hover:text-verdant font-primary group flex rounded-md items-center w-full my-3 py-1 px-3 text-base' +
                                (isActive ? ' verdant' : '')
                            }
                        >
                            <i className="mr-2 fal fa-cog fa-fw text-verdant"></i>
                            Your Profile
                        </NavLink>
                        <Menu.Item as="div">
                            <NavLink
                                end
                                to={`/app/nominate/executives`}
                                className={({ isActive }) =>
                                    'text-black hover:text-verdant font-primary group flex rounded-md items-center w-full my-3 py-1 px-3 text-base' +
                                    (isActive ? ' verdant' : '')
                                }
                            >
                                <i className="mr-2 fal fa-bullhorn fa-fw text-verdant"></i>
                                Nominate
                            </NavLink>
                        </Menu.Item>
                    </Menu.Item>

                    {isAdmin && (
                        <>
                            {/* <Menu.Item as="div">
                                <NavLink
                                    end
                                    to={`/app/settings/organization`}
                                    className={({ isActive }) =>
                                        'text-black hover:text-verdant font-primary group flex rounded-md items-center w-full my-3 py-1 px-3 text-base' +
                                        (isActive ? ' verdant' : '')
                                    }
                                >
                                    <i className="mr-2 fal fa-people-group fa-fw text-verdant"></i>
                                    Organization
                                </NavLink>
                            </Menu.Item> */}
                            <Menu.Item as="div">
                                <a
                                    href="/admin"
                                    className="flex items-center w-full px-3 py-1 my-3 text-base text-black rounded-md hover:text-verdant font-primary group"
                                >
                                    <i className="mr-2 fa-light fa-screwdriver-wrench fa-fw text-verdant"></i>
                                    Admin Panel
                                </a>
                            </Menu.Item>
                        </>
                    )}
                    <div className="mt-1 border-t border-gray-200"></div>
                    <Menu.Item as="div">
                        <NavLink
                            end
                            to={`/app/billing`}
                            className={({ isActive }) =>
                                'text-black hover:text-verdant font-primary group flex rounded-md items-center w-full my-3 py-1 px-3 text-base' +
                                (isActive ? ' verdant' : '')
                            }
                        >
                            <i className="mr-2 fal fa-landmark fa-fw text-verdant"></i>
                            Subscription
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item as="div">
                        <BillingPortalLink />
                    </Menu.Item>
                    <Menu.Item as="div">
                        <div className="mt-1 border-t border-gray-200"></div>
                        <a
                            href="/users/sign_out"
                            className="flex items-center w-full px-3 py-1 my-3 text-base text-red-500 rounded-md hover:text-red-700 font-primary group"
                        >
                            <i className="mr-2 fal fa-sign-out fa-fw"></i>
                            Log Out
                        </a>
                    </Menu.Item>
                </div>
            </Menu.Items>
        </Menu>
    );
};

export default AccountDropDown;

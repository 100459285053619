import React from 'react';
import PlatformIcon from '~/components/platformIcon';

const SourceItem = ({ platform, count }: { platform: string; count: number }): JSX.Element => {
    return (
        <div className="flex flex-row items-center">
            <PlatformIcon platform={platform} className="text-5xl" />
            <h1 className="font-normal ml-4">{count}</h1>
        </div>
    );
};

const TopSources = (): JSX.Element => {
    return (
        <section className="mt-10 pt-5 border-t border-gray-200">
            <div className="flex flex-row justify-between align-text-bottom">
                <h1 className="font-normal mb-5">Top Sources</h1>
                <a href="/app/executives/1/themes" className="text-verdant hover:text-verdant underline">
                    View All
                </a>
            </div>
            <div className="flex flex-row justify-between space-x-20">
                <SourceItem platform="twitter" count={162} />
                <SourceItem platform="youtube" count={121} />
                <SourceItem platform="facebook" count={99} />
                <SourceItem platform="linkedin" count={50} />
                <SourceItem platform="website" count={4} />
            </div>
        </section>
    );
};

export default TopSources;

import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { cn } from '~/utils/ui';
import { useExecutive } from '../api/getExecutive';
import { useSearchUrls } from '~/hooks/useSearchUrls';

const NavBar = (): JSX.Element => {
    const { executiveId } = useParams<string>();
    const { generateSearchByExecutiveUrl } = useSearchUrls();
    if (!executiveId) return <></>;
    const { executive } = useExecutive(executiveId);

    if (!executive) {
        return <></>;
    }

    return (
        <section className="flex flex-row border-b border-gray-200 px-20">
            <NavLink
                to={`/app/executives/${executiveId}`}
                className={({ isActive }) =>
                    cn('mr-4 text-black hover:text-verdant py-2', isActive && 'font-heavy border-b-4 border-cometrics')
                }
            >
                Overview
            </NavLink>
            <NavLink
                to={generateSearchByExecutiveUrl(`${executive.name} - ${executive.company.name}`)}
                className={({ isActive }) =>
                    cn('mx-4 text-black hover:text-verdant py-2', isActive && 'font-bold border-b-4 border-cometrics')
                }
            >
                Content Feed
            </NavLink>
            {/* <NavLink
                to={`/app/executives/${executiveId}/`}
                className={({ isActive }) =>
                    cn('mx-4 text-black hover:text-verdant py-2', isActive && 'font-bold border-b-4 border-cometrics')
                }
            >
                Competitors
            </NavLink> */}
            <NavLink
                to={`/app/executives/${executiveId}/`}
                className={({ isActive }) =>
                    cn('mx-4 text-black hover:text-verdant py-2', isActive && 'font-bold border-b-4 border-cometrics')
                }
            >
                Documents
            </NavLink>
            <NavLink
                to={`/app/company/${executive?.company?.slug}/`}
                className={({ isActive }) =>
                    cn('mx-4 text-black hover:text-verdant py-2', isActive && 'font-bold border-b-4 border-cometrics')
                }
            >
                {executive.company.name}
                <i className="fal fa-external-link text-xs ml-2 text-verdant"></i>
            </NavLink>
        </section>
    );
};

export default NavBar;

import React from 'react';

const TrialWall = (): JSX.Element => {
    return (
        <div className="flex flex-col justify-between mt-5 w-full bg-verdant text-white p-5 rounded-xl">
            <h1 className="text-xl text-left">Looking for more results?</h1>
            <p>
                Upgrade your account to unlock unlimited results, curated feeds and access to our full list of
                companies.
            </p>
            <div className="flex flex-row justify-start mt-5">
                <a
                    href="https://www.cometrics.io/register"
                    className="text-white border-white border-2 p-3 rounded-xl font-semibold hover:text-gray-100 hover:border-gray-200"
                >
                    Chat with sales
                </a>
            </div>
        </div>
    );
};

export default TrialWall;

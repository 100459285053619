import React from 'react';

// import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '~/components/ui/select';

const Competitors = (): JSX.Element => {
    return (
        <section className="mt-10 pt-5 border-t border-gray-200">
            <div className="flex flex-row justify-between align-text-bottom">
                <h1 className="font-normal mb-5">Competitors</h1>
                <a href="/app/executives/1/themes" className="text-verdant hover:text-verdant underline">
                    Explore Competitors
                </a>
            </div>
            {/* <div className="flex flex-row ">
                <Select>
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Most Similar" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="light">Light</SelectItem>
                        <SelectItem value="dark">Dark</SelectItem>
                        <SelectItem value="system">System</SelectItem>
                    </SelectContent>
                </Select>
                <Select>
                    <SelectTrigger className="w-[180px] ml-5">
                        <SelectValue placeholder="In Segment" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="light">Light</SelectItem>
                        <SelectItem value="dark">Dark</SelectItem>
                        <SelectItem value="system">System</SelectItem>
                    </SelectContent>
                </Select>
            </div> */}
            <div className="flex w-full h-64 bg-gray-200 justify-center items-center mt-5">
                <p>Coming Soon</p>
            </div>
        </section>
    );
};

export default Competitors;

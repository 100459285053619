import React from 'react';

import NavBar from '../components/navBar';
import ThumbnailAndBio from '../components/thumbnailAndBio';
import OverviewHeader from '../components/overviewHeader';
import ThemeTrends from '../components/themeTrends';
import ThemeChart from '../components/themeChart';
import RecentContent from '../components/recentContent';
import TopSources from '../components/topSources';
import Documents from '../components/documents';
import Competitors from '../components/competitors';
import RelatedExecutives from '../components/relatedExecutives';
import ThemeComparison from '../components/themeComparison';

const Executive = (): JSX.Element => {
    return (
        <div className="pb-32">
            <NavBar />
            <div className="flex flex-row px-20 mt-10 space-x-20">
                <div className="flex flex-col w-4/5">
                    <OverviewHeader />
                    <ThemeComparison />
                    <RecentContent />
                    <ThemeChart />
                    <ThemeTrends />
                    <TopSources />
                    <Documents />
                    <Competitors />
                </div>
                <div className="flex flex-col border-l border-gray-200">
                    <ThumbnailAndBio />
                    <RelatedExecutives />
                </div>
            </div>
        </div>
    );
};

export default Executive;

import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { Formik, Field, Form, FormikHelpers } from 'formik';

import Api from '~/utils/apiClient';
import useDocumentTitle from '~/hooks/useDocumentTitle';

interface FormInputs {
    email: string;
}

const WaitList = (): JSX.Element => {
    useDocumentTitle('Wait List');
    const [userEmail, setUserEmail] = useState<string>('');

    return (
        <div className="flex items-center justify-center w-full h-full background-offset grow">
            <div className="flex flex-col px-5 py-3 m-5 bg-white rounded-xl 2xl:p-5 max-w-lg">
                <div className="flex flex-col mb-10">
                    <h1 className="text-2xl font-semibold text-black font-primary 2xl:text-3xl">Waiting List</h1>
                    <p className=" text-black font-primary">
                        Please submit your email address to register for Board and Company access in Q4 2024 along with
                        many other features.
                    </p>
                </div>

                <Formik
                    initialValues={{
                        email: '',
                    }}
                    onSubmit={async (values: FormInputs, { setSubmitting, resetForm }: FormikHelpers<FormInputs>) => {
                        const response = await Api.create('/api/waitlist', {
                            waitlist: {
                                email: values.email,
                            },
                        });
                        if (response && !response.error) {
                            setUserEmail(values.email);
                            toast.success('You have been added to our waiting list!');
                            setSubmitting(false);
                            resetForm();
                        } else {
                            toast.error('There was a problem submitting your email to our waiting list.');
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Field
                                id="email"
                                name="email"
                                placeholder="Your Email"
                                className="w-full p-2 text-lg cm-input"
                                required
                            />

                            <button
                                type="submit"
                                className="mt-5 text-lg btn-primary 2xl:text-xl"
                                disabled={isSubmitting}
                            >
                                Join Waiting List
                            </button>

                            <div
                                className={`mt-5 w-full bg-verdant text-center rounded-2xl py-2 px-1 ${
                                    userEmail ? '' : 'hidden'
                                }`}
                            >
                                <p className="text-lg text-white font-primary">
                                    <i className="mr-3 far fa-check"></i> You&apos;ve been added to our waiting list
                                    with email {userEmail}!
                                </p>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default WaitList;

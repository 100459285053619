import React from 'react';

import { useTopSectorThemes } from '../../executives/api/getTopSectorThemes';
import { useTopCompanyThemes } from '../../executives/api/getTopCompanyThemes';
import { useTopThemes } from '../../executives/api/getTopThemes';

import SkeletonCard from '~/components/skeletonCard';
import { useExecutive } from '../../executives/api/getExecutive';
import { Card, CardContent } from '~/components/ui/card';

const ExecutiveThemeComparison = (): JSX.Element => {
    const executiveId = 'michael-miebach';
    const { executive, isLoading } = useExecutive(executiveId);

    const { themes: topSectorThemes } = useTopSectorThemes(executiveId);
    const { themes: topCompanyThemes } = useTopCompanyThemes(executiveId);
    const { themes: topThemes } = useTopThemes(executiveId);

    if (!executive) {
        return <></>;
    }
    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!topThemes) {
        return <></>;
    }
    return (
        <>
            <h2 className="text-xl mt-10 mb-5">Top Theme Comparison</h2>
            <Card className="border-0 shadow-none">
                <CardContent className="pt-6">
                    <div className="flex flex-row justify-between">
                        <div>
                            <h2 className="font-bold">{executive.name}</h2>
                            {topThemes
                                ? topThemes.map((theme: any) => (
                                      <div key={theme.themeName} className="flex flex-row mt-3 align-top">
                                          <h3>{theme.themeName}</h3>
                                      </div>
                                  ))
                                : topThemes}
                        </div>
                        <div>
                            <h2 className="font-bold">{executive?.company.name}</h2>
                            {topCompanyThemes
                                ? topCompanyThemes.map((theme: any) => (
                                      <div key={theme.themeName} className="flex flex-row mt-3 align-top">
                                          <h3>{theme.themeName}</h3>
                                      </div>
                                  ))
                                : null}
                        </div>
                        <div>
                            <h2 className="font-bold">Sector - {executive?.company.sector.name}</h2>
                            {topSectorThemes
                                ? topSectorThemes.map((theme: any) => (
                                      <div key={theme.themeName} className="flex flex-row mt-3 align-top">
                                          <h3>{theme.themeName}</h3>
                                      </div>
                                  ))
                                : null}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

export default ExecutiveThemeComparison;

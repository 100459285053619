// Vendor Imports
import React from 'react';
import { InstantSearch, SearchBox, Configure, ToggleRefinement } from 'react-instantsearch';
import { ErrorBoundary } from 'react-error-boundary';

// Search Related Imports
import MultiSelectFilter from './multiselectFilter';
import DateFilter from './dateFilter';
import Stats from './stats';
import ThemeBrowser from './themeBrowser';
import SearchPagination from './searchPagination';

// Layout Imports
import CardRow from '../layout/cardRow';
import { Container, ContainerWrapper } from '../layout/container';
import Sidebar from '../layout/sidebar';
import IndexScopeSelector from '../settings/indexScopeSelector';
import Spinner from '~/components/spinner';
import ExportSearchPage from './exportSearchPage';
import CompanyBrowser from './companyBrowser';
import TrialWall from './trialWall';

import { useAuth } from '~/hooks/useAuth';
import useDocumentTitle from '~/hooks/useDocumentTitle';

interface SearchProps {
    filter?: string;
    title?: string;
    hideClearFilter?: boolean;
}

const SearchProvider = (props: SearchProps): JSX.Element => {
    const { user, isAdmin, meili, meiliClient } = useAuth();

    useDocumentTitle('Search Content');
    return (
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
            {meili && meiliClient ? (
                <InstantSearch indexName={meili.indexes['content']} searchClient={meiliClient} routing={true}>
                    {props?.filter && <Configure filters={props.filter} />}

                    <ContainerWrapper>
                        <Sidebar header="Search Filters">
                            <h4 className="pb-1 text-sm font-semibold text-left font-primary 2xl:text-lg">
                                Search Title & Description
                            </h4>
                            <SearchBox />
                            <h4 className="pb-1 mt-5 text-sm font-semibold text-left 2xl:mt-5 font-primary 2xl:text-lg">
                                Date Range
                            </h4>
                            <DateFilter attribute="publisher_created_at_i" />

                            <div className="flex items-end justify-between">
                                <h4 className="pb-1 mt-5 text-sm font-semibold text-left 2xl:mt-5 font-primary 2xl:text-lg">
                                    Themes
                                </h4>
                                <ThemeBrowser attribute="themes.name">
                                    <p className="pb-1 text-sm font-semibold text-right cursor-pointer font-primary 2xl:text-lg text-verdant hover:opacity-80">
                                        Browse
                                    </p>
                                </ThemeBrowser>
                            </div>
                            <MultiSelectFilter
                                attribute="themes.name"
                                placeholderText="Filter Themes"
                                limit={1000}
                                operator="or"
                                sortBy={['name', 'count:desc']}
                            />

                            <div className="flex items-end justify-between">
                                <h4 className="pb-1 mt-5 text-sm font-semibold text-left 2xl:mt-5 font-primary 2xl:text-lg">
                                    Companies
                                </h4>
                                <CompanyBrowser attribute="company.name">
                                    <p className="pb-1 text-sm font-semibold text-right cursor-pointer font-primary 2xl:text-lg text-verdant hover:opacity-80">
                                        Browse
                                    </p>
                                </CompanyBrowser>
                            </div>
                            <MultiSelectFilter
                                attribute="company.name"
                                placeholderText="Filter Companies"
                                limit={1000}
                                operator="or"
                                sortBy={['name', 'count:desc']}
                            />

                            {/* <h4 className="pb-1 mt-5 text-sm font-semibold text-left 2xl:mt-5 font-primary 2xl:text-lg">
                                Country
                            </h4>
                            <MultiSelectFilter
                                attribute="country.name"
                                placeholderText="Filter Countries"
                                limit={100}
                            /> */}

                            {/* <h4 className="pb-1 mt-5 text-sm font-semibold text-left 2xl:mt-5 font-primary 2xl:text-lg">City</h4>
                        <MultiSelectFilter attribute="city" placeholderText="Filter Cities" /> */}

                            <h4 className="pb-1 mt-5 text-sm font-semibold text-left 2xl:mt-5 font-primary 2xl:text-lg">
                                Platform
                            </h4>
                            <MultiSelectFilter attribute="platform" placeholderText="Filter Platform" limit={100} />

                            <h4 className="pb-1 mt-5 text-sm font-semibold text-left 2xl:mt-5 font-primary 2xl:text-lg">
                                Media Type
                            </h4>
                            <MultiSelectFilter
                                attribute="media_type.name"
                                placeholderText="Filter Media Type"
                                limit={100}
                            />

                            <h4 className="pb-1 mt-5 text-sm font-semibold text-left 2xl:mt-5 font-primary 2xl:text-lg">
                                Sector
                            </h4>
                            <MultiSelectFilter
                                attribute="sector.name"
                                placeholderText="Filter Sector"
                                limit={1000}
                                operator="or"
                                sortBy={['name', 'count:desc']}
                            />

                            <h4 className="pb-1 mt-5 text-sm font-semibold text-left 2xl:mt-5 font-primary 2xl:text-lg">
                                Industry
                            </h4>
                            <MultiSelectFilter
                                attribute="industry.name"
                                placeholderText="Filter Industry"
                                limit={1000}
                                operator="or"
                                sortBy={['name', 'count:desc']}
                            />

                            <h4 className="pb-1 mt-5 text-sm font-semibold text-left 2xl:mt-5 font-primary 2xl:text-lg">
                                Tags
                            </h4>
                            <MultiSelectFilter attribute="labels" placeholderText="Filter Tags" limit={20} />

                            <h4 className="pb-1 mt-5 text-sm font-semibold text-left 2xl:mt-5 font-primary 2xl:text-lg">
                                Executives
                            </h4>
                            <MultiSelectFilter
                                attribute="executive_name"
                                placeholderText="Filter Executives"
                                limit={1000}
                            />

                            <ToggleRefinement attribute="is_ad" label="Advertisements Only" style={{ marginTop: 5 }} />

                            <ToggleRefinement attribute="has_media" label="Media Attached" />
                            <ToggleRefinement attribute="is_executive" label="Executive Only" />
                            <ToggleRefinement attribute="is_document" label="Documents Only" />
                            {!props.hideClearFilter && (
                                <div
                                    className="w-full mt-5 text-left"
                                    onClick={() => localStorage.setItem('searchState', '')}
                                >
                                    <a
                                        href="/app/search"
                                        className="text-lg font-semibold font-primary text-verdant hover:text-verdantDark"
                                    >
                                        <i className="mr-2 fal fa-magnifying-glass-minus" />
                                        Clear all filters
                                    </a>
                                </div>
                            )}
                            <ExportSearchPage>
                                <button className="flex items-center pt-5 text-lg font-semibold font-primary text-verdant hover:text-verdantDark">
                                    <i className="mr-2 fal fa-file-export" />
                                    Export Page Results
                                </button>
                            </ExportSearchPage>
                            {isAdmin ? (
                                <IndexScopeSelector indexScopeName={user?.index_scope?.name}>
                                    <div className="w-full mt-5 text-left">
                                        <p className="text-lg font-semibold cursor-pointer font-primary text-verdant hover:text-verdantDark">
                                            <i className="mr-2 fal fa-telescope" />
                                            Update Index Scope
                                        </p>
                                    </div>
                                </IndexScopeSelector>
                            ) : null}
                        </Sidebar>

                        <Container>
                            <Stats />
                            <CardRow />
                            {user?.team?.trial || !user?.team?.executives ? <TrialWall /> : <SearchPagination />}
                        </Container>
                    </ContainerWrapper>
                </InstantSearch>
            ) : (
                <Spinner />
            )}
        </ErrorBoundary>
    );
};

export default SearchProvider;

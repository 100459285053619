import React from 'react';

import SkeletonCard from '~/components/skeletonCard';

import { useFeaturedExecutive } from '../api/getFeaturedExecutive';
import { Card, CardContent, CardDescription } from '~/components/ui/card';
import { NavLink } from 'react-router-dom';

const ExecutiveBioAndThumbnail = () => {
    const { executive, isLoading } = useFeaturedExecutive('michael-miebach');

    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!executive) {
        return <></>;
    }
    return (
        <Card className="border-0 shadow-none">
            <CardContent className="pt-6">
                <div className="flex">
                    <img
                        alt="Executive Thumbnail"
                        src={executive.profile_thumbnail_url}
                        className="bg-white rounded-xl w-full max-w-[150px] object-cover aspect-square"
                    />
                    <div className="ml-5">
                        <NavLink to={`/app/executives/${executive.route}`}>
                            <h2>{executive.name}</h2>
                            <CardDescription>{executive.title}</CardDescription>
                        </NavLink>
                        <p className="pt-3">{executive.summary}</p>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default ExecutiveBioAndThumbnail;

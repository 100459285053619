import React, { useState, useEffect } from 'react';
import { Container, ContainerWrapper } from '../../layout/container';
import Sidebar from '../../layout/sidebar';
import AccountMenu from '../menu';
import ApiClient from '~/utils/apiClient';

import EditTeam from './editTeam';
import TeamDetails from './details';
import Plans from './plans';
import UserTable from './userTable';

const Team = (): JSX.Element => {
    const [refreshTeam, setRefreshTeam] = useState<boolean>(false);
    const [team, setTeam] = useState<any>();
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const getTeam = async () => {
        setTeam(await ApiClient.get('/api/team'));
    };

    useEffect(() => {
        getTeam();
    }, []);

    useEffect(() => {
        if (refreshTeam) {
            getTeam();
            setRefreshTeam(false);
        }
    }, [refreshTeam]);

    return (
        <ContainerWrapper>
            <Sidebar header="Account Menu">
                <AccountMenu />
            </Sidebar>
            <Container>
                <div className="flex justify-center items-align-center">
                    <div>
                        {/* {team && team?.subscription_status === 'trial' && <Plans />}
                        <div className="flex flex-row justify-between mb-2">
                            <h1 className="text-2xl 2xl:text-3xl font-primary">Organization</h1>
                            <div className="btn-primary-outline" onClick={() => setIsEditing(!isEditing)}>
                                {isEditing ? 'Hide Panel' : 'Edit'}
                            </div>
                        </div> */}
                        {team && <TeamDetails team={team} />}
                        {/* {team && isEditing ? (
                            <EditTeam id={team.id} teamName={team.name} refreshTeam={setTeam} />
                        ) : null}
                        {team && <UserTable teamId={team.id} />} */}
                    </div>
                </div>
            </Container>
        </ContainerWrapper>
    );
};

export default Team;

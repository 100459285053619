import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import { ProtectedRoute } from './protectedRoute';
import ErrorBoundary from './errorBoundary';

import { AccountDetails, AccountPassword, ManageOrganization } from '../settings';
import DashboardHome from '../home/index';
import ContentDetails from '../content/content';
import Menu from '../layout/menu';
import Footer from '../layout/footer';
import SaveGroups from '../saved/index';
import SavedContent from '../saved/savedContent';
import Visualizations from '../visualization/index';
import Alerts from '../alerts/alerts';
import CompanySnapshot from '../company/companySnapshot';
import CompanyCompetitors from '../company/competitors';
import SearchProvider from '../search/search';
import Companies from '../company/companies';
import Document from '../documents/document';

import { ExecutiveRoutes } from '../executives';
import { NominateRoutes } from '../nominate';
import { WaitlistRoutes } from '../waitlist';
import { OverviewRoutes } from '../overview';
import { BillingRoutes } from '../billing';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }, [pathname]);

    return null;
};

const ComingSoon = (): JSX.Element => {
    return (
        <section className="flex flex-row content-center justify-center h-full py-20 grow background-offset">
            <div className="flex flex-col content-center w-96">
                <h1 className="text-2xl font-primary">Feature Coming Soon</h1>
                <div className="p-5 mt-3 bg-white w-100 rounded-xl">
                    You found a feature that&apos;s almost complete!
                    <br />
                    <br />
                    Stay tuned while we put the finishing touches on.
                </div>
            </div>
        </section>
    );
};

const AppRoutes = (): JSX.Element => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Menu />
            <div className="flex flex-col justify-between w-full h-full min-w-full min-h-full grow">
                <SentryRoutes>
                    <Route
                        path="/app/dashboard/"
                        element={
                            <ProtectedRoute>
                                <OverviewRoutes />
                                {/* <DashboardHome /> */}
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/billing"
                        element={
                            <ProtectedRoute>
                                <BillingRoutes />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/executives/*"
                        element={
                            <ProtectedRoute>
                                <ExecutiveRoutes />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/search/*"
                        element={
                            <ProtectedRoute>
                                <SearchProvider />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/companies"
                        index
                        element={
                            <ProtectedRoute>
                                <Companies />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/app/company/:companyId/"
                        element={
                            <ProtectedRoute>
                                <CompanySnapshot />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/company/:companyId/document/:documentId/"
                        element={
                            <ProtectedRoute>
                                <Document />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/company/:companyId/document/:documentId/page/:documentPageId/"
                        element={
                            <ProtectedRoute>
                                <Document />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/company/:companyId/competitors/"
                        element={
                            <ProtectedRoute>
                                <CompanyCompetitors />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/alerts/"
                        element={
                            <ProtectedRoute>
                                <Alerts />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/content/:contentId/*"
                        element={<ContentDetails />}
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/settings/profile/"
                        element={
                            <ProtectedRoute>
                                <AccountDetails />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/settings/profile/password/"
                        element={
                            <ProtectedRoute>
                                <AccountPassword />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/settings/organization/"
                        element={
                            <ProtectedRoute>
                                <ManageOrganization />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/app/nominate/*"
                        element={
                            <ProtectedRoute>
                                <NominateRoutes />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/saved/*"
                        element={
                            <ProtectedRoute>
                                <SaveGroups />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/saved/:saveGroupId/*"
                        element={
                            <ProtectedRoute>
                                <SavedContent />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route
                        path="/app/visualizations/*"
                        element={
                            <ProtectedRoute>
                                <Visualizations />
                            </ProtectedRoute>
                        }
                        errorElement={<ErrorBoundary />}
                    />
                    <Route path="/app/waitlist/*" element={<WaitlistRoutes />} errorElement={<ErrorBoundary />} />
                    <Route path="/app/coming-soon/*" element={<ComingSoon />} errorElement={<ErrorBoundary />} />
                </SentryRoutes>
            </div>

            <Footer />
        </BrowserRouter>
    );
};

export default AppRoutes;

import React from 'react';

const ThemeChart = (): JSX.Element => {
    return (
        <section className="mt-10 pt-5 border-t border-gray-200">
            <h1 className="font-normal mb-5">Themes Over Time</h1>
            <div className="flex w-full h-64 bg-gray-200 justify-center items-center">
                <p>Coming Soon</p>
            </div>
        </section>
    );
};

export default ThemeChart;

import React from 'react';
import { InstantSearch, SearchBox, useHits, UseHitsProps } from 'react-instantsearch';
import { ContainerWrapper } from '~/dashboard/layout/container';
import { useAuth } from '~/hooks/useAuth';
import Spinner from '~/components/spinner';
import MultiSelectFilter from '~/dashboard/search/multiselectFilter';
import SearchPagination from '~/dashboard/search/searchPagination';
import ResultCard from './resultCard';

const CardRow = (props: UseHitsProps): JSX.Element => {
    const { hits, results } = useHits(props);
    if (!hits || typeof results?.nbHits != 'undefined') {
        return (
            <>
                {hits.map((hit: any) => {
                    if (hit.active) {
                        return <ResultCard hit={hit} key={hit.objectID || hit.id} />;
                    } else {
                        return null;
                    }
                })}
            </>
        );
    } else {
        return <Spinner />;
    }
};

const SearchAndFilter = (): JSX.Element => {
    const { meili, meiliClient } = useAuth();
    return (
        <ContainerWrapper className="px-5 background-offset pb-10">
            {meili && meiliClient ? (
                <InstantSearch indexName={meili.indexes['executive']} searchClient={meiliClient} routing={true}>
                    <div className="container flex flex-col mx-auto mt-10">
                        <header className="flex flex-col lg:flex-row lg:items-center justify-between mb-10">
                            <h1 className="text-3xl font-semibold text-black font-primary">Executives</h1>
                            <div className="flex space-x-3">
                                <MultiSelectFilter
                                    attribute="company.sector.name"
                                    placeholderText="Filter by Sector"
                                    limit={1000}
                                    operator="or"
                                    sortBy={['name', 'count:desc']}
                                />
                                <MultiSelectFilter
                                    attribute="company.name"
                                    placeholderText="Filter by Company"
                                    limit={1000}
                                    operator="or"
                                    sortBy={['name', 'count:desc']}
                                />
                                <SearchBox />
                            </div>
                        </header>
                        <div className="grid grid-flow-row grid-cols-1 gap-10 mb-10 sm:grid-cols-2 xl:grid-cols-4 auto-rows-max">
                            <CardRow />
                        </div>
                        <SearchPagination />
                    </div>
                </InstantSearch>
            ) : (
                <Spinner />
            )}
        </ContainerWrapper>
    );
};

export default SearchAndFilter;
